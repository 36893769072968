* {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

.sidebar-nav {
  background-color: #005088;

  .nav-dropdown.open {
    background-color: #005088;
  }

  .nav-link.active {
    background-color: #013e69;
    font-weight: bold;
  }
}

div.sidebar ul.nav li.nav-item:first-child a.nav-link.active {
  background-color: #005088 !important;
  font-weight: inherit;
}

div.sidebar ul.nav-dropdown-items li.nav-item:first-child a.nav-link.active {
  background-color: #013e69 !important;
  font-weight: bold;
}

.sidebar .nav-link.active .nav-icon {
  color: white;
}
.sidebar .nav-item .nav-icon {
  margin: 0 1.2rem 0 0;
}

.card-login {
  width: 360px;
}

.bg-azul {
  background-color: #005088;
}

.bg-detalhe {
  background-color: #f0f3f5;
}

.detalhe-tabela-carregando {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 80px;
}

.card {
  border: none;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.card-icon-dash {
  width: 110px;
  height: 90px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 4px 0px 0px 4px;
}

.filter-margin {
  margin: 0;

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
}

.Toastify__toast-container {
  border-radius: 4px;
  border: none;
  text-align: center;
  .Toastify__toast--default {
    border-radius: 4px;
    background-color: white;
    color: #005088;
  }
  .Toastify__progress-bar--default {
    background: #063b61;
  }
  .Toastify__close-button:hover,
  .Toastify__close-button:focus {
    outline: none;
    border: none;
  }
}

@for $i from 12 through 30 {
  .f-#{$i} {
    font-size: 1px * $i;
  }
}

@for $i from 1 through 24 {
  .MuiPaper-elevation#{$i} {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }
}

.nav .nav-item .nav-icon {
  font-size: 18px;
}

.card-filtro-campanha {
  border-radius: 4px;
  border: 1px solid #e4e7ea;
  background-color: white;
  white-space: pre-wrap;
  height: auto;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    height: 210px;
    span {
      height: 100px;
    }
  }

  @media screen and (min-width: 1390px) {
    height: 170px;
  }

  &:hover {
    background-color: #eaeaea;
  }

  p {
    margin: 0;
    padding: 10px;
    font-weight: bold;
  }

  span {
    margin: 10px;
    text-align: left;
    white-space: pre-wrap;
  }

  input {
    width: 50px;
    border: 1px solid #eaeaea;
    outline: none;
  }
}

.demo-editor {
  min-height: 400px;
  width: 100%;
}

.demo-wrapper {
  width: 100%;
}

.editor-html,
.editor-html:active,
.editor-html:hover,
.editor-html:focus {
  border: 1px solid #e4e7ea;
  outline: none;
  border-radius: 4px;
  min-height: 200px;
}

.btn-limpo,
.btn-limpo:hover,
.btn-limpo:active,
.btn-limpo:focus,
.btn-limpo:visited {
  all: unset;
  color: black;
  -webkit-text-fill-color: black;
  cursor: pointer;
  outline: 0;
  border: none;
}

button:focus {
  outline: 0;
}

.MuiTableCell-body {
  white-space: nowrap;
}

.MuiTableCell-head {
  white-space: nowrap;
}

.paginator {
  font-size: 0.875rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  color: black;

  .MuiInputBase-root {
    width: 100px;
  }
}

.form-cadastro {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 400px;
}

.c-primary {
  color: #005088;
}

.c-white {
  color: white;
}

.c-black {
  color: black;
}

.list-group-item:hover {
  background-color: #e4e7ea !important;
}

.m-form-input {
  margin-left: 35px;
}
